import React from 'react';
import { Popover, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface MessagePopupProps {
    messages: string[];
}

const MessagePopup: React.FC<MessagePopupProps> = ({ messages }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                size="small"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ ml: 1 }}
            >
                <InfoIcon color="action" fontSize="small" />
            </IconButton>
            <Popover
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>Messages:</Typography>
                <List dense>
                    {messages.map((message, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={`• ${message}`} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    );
};

export default MessagePopup;