import { Resolver } from "react-hook-form";
import belliniOdaPlugin from './belliniOda/belliniOdaPlugin';

export interface PipelinePlugin {

    // The react-hook-form resolver for this pipeline used to validate the form
    getResolver: () => Resolver | undefined;

    // vvv Add other plugin methods as needed vvv
}


// The default plugin to use when no plugin is found
const defaultPlugin: PipelinePlugin = {
    getResolver: () => undefined,
    // Add other plugin methods as needed
};

// Contains all the available pipeline plugins
const plugins: Record<string, PipelinePlugin> = {
    bellini_oda: belliniOdaPlugin,
};

/**
 * Get the pipeline plugin by name
 * @param pipeName The name of the pipeline
 * @returns The pipeline plugin
 */
export function getPipelinePluginByName(pipeName: string | undefined | null): PipelinePlugin {
    if (!pipeName) {
        return defaultPlugin;
    }

    return plugins[pipeName] || defaultPlugin;
}