import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IOrganizationBase, IOrganizationCreate, IOrganizationRead, IOrganizationUpdate, IOrganizationDelete } from "../auth/types";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetOrganizationQuery, useCreateOrganizationMutation, useGetOrganizationsQuery, useUpdateOrganizationMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { skipToken } from '@reduxjs/toolkit/query';

const DEFAULT_CREATE_VALUES: IOrganizationCreate = {
  name: "",
  is_deactivated: false,
};

const cleanReadOrganizationDataForUpdate = (organization: IOrganizationRead): IOrganizationUpdate => {
  const { id, name, is_deactivated, created_at, updated_at, ...rest } = organization;
  return { name, is_deactivated };
};

interface IOrganizationFormDialogProps {
  organizationId?: IOrganizationRead["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing organization.
 * It can be opened in two modes:
 * 1. Create mode: organizationId is not given
 * 2. Edit mode: organizationId is given
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */
const OrganizationFormDialog: React.FC<IOrganizationFormDialogProps> = ({ organizationId, render }) => {
  const [open, setOpen] = useState(false);

  const createMode: boolean = !organizationId;

  const form = useForm<IOrganizationCreate | IOrganizationUpdate>();

  // Delay data fetching until userId is defined and dialog is opened
  const { data: organization, isLoading: isDataLoading } = useGetOrganizationQuery(organizationId ?? skipToken, { skip: !open });

  const [triggerCreate, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateOrganizationMutation();
  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateOrganizationMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  React.useEffect(() => {
    if (organization) {
      form.reset(cleanReadOrganizationDataForUpdate(organization));
    } else {
      form.reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const handleClose = () => {
    setOpen(false);
  };

  const sendData = (data: IOrganizationCreate | IOrganizationUpdate) => {
    //parse is_deactivated from string to boolean, use if for true and false
    data.is_deactivated = String(data.is_deactivated) === "true" ? true : false;
    if (createMode) {
      triggerCreate(data as IOrganizationCreate);
    } else {
      triggerUpdate({ id: organizationId || "", ...data as Omit<typeof data, 'id'> });
    }
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar("Errore creazione", { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar("Creata con successo", { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornata con successo", { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );
  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? "Crea nuova organizzazione" : "Modifica organizzazione"}</DialogTitle>
        {!isDataLoading ? (
          <form onSubmit={form.handleSubmit(sendData)}>
            <DialogContent>
              <Stack gap={2}>
                <Controller
                  name="name"
                  rules={{ required: "Il nome è obbligatorio" }}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Nome"
                      type="text"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message || ""}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="is_deactivated"
                  control={form.control}
                  defaultValue={false}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth required error={!!fieldState.error}>
                      <InputLabel id="is-deactivated-label">Is Deactivated</InputLabel>
                      <Select
                        labelId="is-deactivated-label"
                        label="Is Deactivated"
                        {...field}
                      >
                        <MenuItem value="false">False</MenuItem>
                        <MenuItem value="true">True</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
              <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
                Annulla
              </Button>
              <LoadingButton
                startIcon={<CheckOutlinedIcon />}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                type="submit"
              >
                {createMode ? "Crea" : "Aggiorna"}
              </LoadingButton>
            </DialogActions>
          </form>
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
export default OrganizationFormDialog;
