import React, { useRef, useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Paper, Box, Button, Typography } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// Load worker from external source. this is done to avoid too big bundle size. it's loaded only on demand
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.4.168/build/pdf.worker.mjs';

interface IPdfDocRendererProps {
    onFileSelect: (file: File) => void;
    file: File | null;
    page: number;
    onPageChange: (pageNumber: number) => void;
    onMaxPageChange: (maxPage: number) => void;
    debugMode: boolean;
    onDimensionsChange: (width: number, height: number) => void;
    onFileNameChange?: (fileName: string) => void;
    scale: number;
}

const PdfDocRenderer = forwardRef<HTMLDivElement, IPdfDocRendererProps>(({ onFileSelect, file, onPageChange, debugMode, onMaxPageChange, page, onDimensionsChange, onFileNameChange, scale}, ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && droppedFile.type === 'application/pdf') {
            onFileSelect(droppedFile);
        }
    };

    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        onMaxPageChange(numPages);
        onPageChange(1);
    };

    const handlePageLoadSuccess = ({ width, height }: { width: number; height: number }) => {
        onDimensionsChange(width, height);
    };

    const handleFileChange = (selectedFile: File) => {
        if (selectedFile) {
            const fileName = selectedFile.name;
            if (fileName && fileName.length > 0) {
                onFileNameChange && onFileNameChange(fileName);
            }
            onFileSelect(selectedFile);
            onPageChange(1);
        }
    };

    const renderPdfViewer = () => (
            <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
                    <Page
                        key={`page_${page}_scale_${scale}`}
                        pageNumber={page}
                        onLoadSuccess={handlePageLoadSuccess}
                        scale={scale}
                    />
            </Document>
    );

    const renderDropZone = () => (
        <Paper
            sx={{
                height: '100%',
                border: isDragging ? '2px dashed #2196f3' : '2px dashed #ccc',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                overflow: 'auto',
                position: 'relative',
            }}
            onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
            }}
            onDragLeave={() => setIsDragging(false)}
            onDrop={handleDrop}
            onClick={() => inputRef.current?.click()}
        >
            <input
                type="file"
                ref={inputRef}
                hidden
                accept="application/pdf"
                onChange={(e) => {
                    const selectedFile = e.target.files?.[0];
                    if (selectedFile) {
                        handleFileChange(selectedFile);
                    }
                }}
            />
            <Box>Drop PDF here or click to select</Box>
        </Paper>
    );
    return (
        <div ref={ref} style= {{width: '100%', height: '100%'}}>
            {file ? renderPdfViewer() : renderDropZone()}
        </div>
    );
});

export default PdfDocRenderer;
