import React, { } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Stack } from "@mui/system";
import { useDebugInfoQuery } from "../../app/services/appApi";
import DevSettingsForm from "./devSettings/DevSettingsForm";
import { Link } from "react-router-dom";


const DeveloperPage: React.FC = () => {
  const { data } = useDebugInfoQuery();

  return (
    <>
      <Typography variant="h4">Debug page</Typography>
      <Stack spacing={2}>
        <Typography variant="h6">Dev pages</Typography>
        <Stack direction="row" spacing={1}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/labeller"
          >
            Go to Labeller
          </Button>
          {/* Other buttons to go to:
        /admin/user
        /admin/pipeline
        /admin/organization */}
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/admin/user"
          >
            Go to User Admin
          </Button>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/admin/pipeline"
          >
            Go to Pipeline Admin
          </Button>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/admin/organization"
          >
            Go to Organization Admin
          </Button>
        </Stack>

        <Typography variant="h6">Frontend info</Typography>
        <Typography variant="body1" component="p">
          Version: {process.env.REACT_APP_VERSION}
          <br />
          Environment: {process.env.NODE_ENV}
        </Typography>
        <Typography variant="h6">Backend info</Typography>
        <Typography variant="body1" component="p">
          {JSON.stringify(data)}
        </Typography>

        <Typography variant="h6">Crash frontend</Typography>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            throw new Error("Crash frontend");
          }}
        >
          Crash frontend
        </Button>

        <Typography variant="h6">Dev settings</Typography>
        <DevSettingsForm />



      </Stack>
    </>
  );
};

export default DeveloperPage;
