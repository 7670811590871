import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IPipelineBase, IPipelineCreate, IPipelineRead, IPipelineUpdate, IPipelineDelete } from "../auth/types";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetPipelineQuery, useCreatePipelineMutation, useGetPipelinesQuery, useUpdatePipelineMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { skipToken } from '@reduxjs/toolkit/query';
import SchemaDocsDialog from './SchemaDocsDialog';
const DEFAULT_CREATE_VALUES: IPipelineCreate = {
  code: "",
  title: "",
  subtitle: "",
  ratelimit: 1000,
  ui_schema: "",
  extraction_schema: "",
  extraction_extra_context: "",
  pipe_name: "",
  organization_id: "",
};

const cleanReadPipelineDataForUpdate = (pipeline: IPipelineRead): IPipelineUpdate => {
  const { id, code, title, subtitle, ratelimit, pipe_name, created_at, updated_at, ui_schema, organization,
    extraction_extra_context, extraction_schema
  } = pipeline;
  return {
    code, title, subtitle, ratelimit, pipe_name, ui_schema: JSON.stringify(ui_schema),
    extraction_schema: JSON.stringify(extraction_schema),
    extraction_extra_context,
    organization_id: organization.id
  };
};

interface IPipelineFormDialogProps {
  pipelineId?: IPipelineRead["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing pipelines.
 * It can be opened in two modes:
 * 1. Create mode: pipelineId is not given
 * 2. Edit mode: pipelineId is given
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */
const PipelineFormDialog: React.FC<IPipelineFormDialogProps> = ({ pipelineId, render }) => {
  const [open, setOpen] = useState(false);

  const createMode: boolean = !pipelineId;

  const form = useForm<IPipelineCreate | IPipelineUpdate>();

  // Delay data fetching until userId is defined and dialog is opened
  const { data: pipeline, isLoading: isDataLoading } = useGetPipelineQuery(pipelineId ?? skipToken, { skip: !open });

  const [triggerCreate, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreatePipelineMutation();
  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdatePipelineMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  React.useEffect(() => {
    if (pipeline) {
      form.reset(cleanReadPipelineDataForUpdate(pipeline));
    } else {
      form.reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline]);

  const handleClose = () => {
    setOpen(false);
  };

  const sendData = (data: IPipelineCreate | IPipelineUpdate) => {
    // Convert ui_schema to JSON
    data.ui_schema = JSON.parse(data.ui_schema);
    data.extraction_schema = data.extraction_schema ? JSON.parse(data.extraction_schema) : null;
    if (createMode) {
      triggerCreate(data as IPipelineCreate);
    } else {
      triggerUpdate({ id: pipelineId || -1, ...data });
    }
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar("Errore creazione", { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar("Creata con successo", { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornata con successo", { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );
  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? "Crea nuova pipeline" : "Modifica pipeline"}</DialogTitle>
        {!isDataLoading ? (
          <form onSubmit={form.handleSubmit(sendData)}>
            <DialogContent>
              <Stack gap={2}>
                <Controller
                  name="code"
                  rules={{ required: true, maxLength: 6 }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      autoFocus
                      label={"Code"}
                      type="text"
                      placeholder="123456"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo deve contenere massimo 6 caratteri" : "Codice a 6 cifre es. 123456"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="title"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Title"}
                      type="text"
                      placeholder="La mia pipeline"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Nome pipeline da mostrare nella UI"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="subtitle"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Subtitle"}
                      type="text"
                      placeholder="Pipeline che fa cose"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Sottotitolo pipeline da mostrare nella UI"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="organization_id"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"ID Organizzazione"}
                      type="text"
                      placeholder="42"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "ID dell'organizzazione a cui associare la pipeline"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="ratelimit"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue={1000}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Ratelimit mensile"}
                      type="text"
                      placeholder="1000"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error && "Il campo è obbligatorio"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="extraction_extra_context"
                  control={form.control}
                  defaultValue=""
                  rules={{ maxLength: 4096 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Extraction extra context"}
                      type="text"
                      fullWidth
                      multiline
                      placeholder="# Additional context\n- The document is a purchase order"
                      rows={4}
                      maxRows={8}
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo deve contenere massimo 4096 caratteri" :
                        "Opzionale. Per pipeline generiche. Specifica il contesto dell'estrazione in markdown. max 4096 caratteri"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="extraction_schema"
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Extraction JsonSchema"}
                      type="text"
                      fullWidth
                      multiline
                      placeholder="{}"
                      rows={4}
                      error={!!fieldState.error}
                      helperText={"Opzionale. Per pipeline generiche"}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="ui_schema"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Ui Schema"}
                      type="text"
                      fullWidth
                      placeholder="{}"
                      required
                      multiline
                      rows={4}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? "Il campo è obbligatorio" : "Schema per determinare quali campi UI mostrare"}
                      InputProps={{
                        endAdornment: <SchemaDocsDialog />
                      }}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="pipe_name"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Pipe Name"}
                      type="text"
                      placeholder='base_ddt'
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Nome da passare nel create_pipeline_by_name(...)"}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
              <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
                Annulla
              </Button>
              <LoadingButton
                startIcon={<CheckOutlinedIcon />}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                type="submit"
              >
                {createMode ? "Crea" : "Aggiorna"}
              </LoadingButton>
            </DialogActions>
          </form>
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
export default PipelineFormDialog;
