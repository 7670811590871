import { Box, Button, Typography, IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useState } from 'react';

interface ILabellerPageNavigatorProps {
    value: number; // 1-indexed
    onChange: (value: number) => void;
    maxPage: number // 1-indexed
    onChangeFile:() => void;
    scale: number;
    onChangeScale: (scale: number) => void;
}

const LabellerPageNavigator: React.FC<ILabellerPageNavigatorProps> = ({
    value,
    onChange,
    maxPage,
    onChangeFile,
    scale,
    onChangeScale

}) => {
    const changePage = (offset: number) => {
        const newValue = value + offset;
        if (newValue >= 1 && newValue <= maxPage) {
            onChange(newValue);
        }
    };

    const changeScale = (offset: number) => {
        const newScale = scale + offset;

        if (newScale >= 0.1 && newScale <= 5) {
            onChangeScale(newScale);
        }
    }


    return (

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button onClick={onChangeFile}>
                Change PDF
            </Button>
            <Button onClick={() => changePage(-1)} disabled={value <= 1}>
                Previous
            </Button>
            <Typography>
                Page {value} of {maxPage}
            </Typography>
            <Button onClick={() => changePage(1)} disabled={value >= (maxPage || 1)}>
                Next
            </Button>
            
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <IconButton onClick={()=> changeScale(-0.1)}>
                    <ZoomOutIcon />
                </IconButton>
                <Typography sx={{ mx: 1 }}>
                    {(scale * 100).toFixed(0)}%
                </Typography>
                <IconButton onClick={()=> changeScale(0.1)}>
                    <ZoomInIcon />
                </IconButton>
            </Box>
            
        </Box>

    );
};

export default LabellerPageNavigator;
