import React, { useMemo } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { BoundingBox, PageTag } from './labellerTypes';

interface MergedMetadata {
    tags: string[];
    annotations: {
        bbox: number[];
        annotation_name: string;
    }[];
}

interface Props {
    boundingBoxes: BoundingBox[];
    tags: PageTag[] | undefined;
    originalFileName: string;
}

const MergedMetadata: React.FC<Props> = ({ originalFileName, boundingBoxes, tags }) => {
    const mergedData = useMemo(() => {
        const maxPage = Math.max(
            ...boundingBoxes.map(box => box.page),
            ...(tags?.map(tag => tag.page) || [])
        );

        const result: MergedMetadata[] = Array.from({ length: maxPage }, () => ({
            tags: [],
            annotations: [],
        }));

        tags?.forEach(tag => {
            const pageIndex = tag.page - 1;
            if (!result[pageIndex].tags.includes(tag.tagName)) {
                result[pageIndex].tags.push(tag.tagName);
            }
        });

        boundingBoxes.forEach(box => {
            const pageIndex = box.page - 1;
            result[pageIndex].annotations.push({
                bbox: [box.x, box.y, box.width, box.height],
                annotation_name: box.label.name,
            });
        });

        return result;
    }, [boundingBoxes, tags]);

    const handleDownload = () => {
        const dataStr = JSON.stringify(mergedData, null, 2);
        const dataBlob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        const fileName = `${originalFileName}.json`;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <TextField
                multiline
                fullWidth
                variant="outlined"
                value={JSON.stringify(mergedData, null, 2)}
                disabled
                sx={{
                    mt: 1,
                    flexGrow: 1,
                    '& .MuiInputBase-root': {
                        height: '100%',
                    },
                    '& .MuiInputBase-input': {
                        height: '100% !important',
                        overflow: 'auto !important',
                    },
                }}
            />
            <Button sx={{ mt: 2 }} variant="contained" onClick={handleDownload}>
                Download Merged Metadata
            </Button>
        </>
    );
};

export default MergedMetadata;
