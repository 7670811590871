import React, { useEffect, useState } from "react";
import {
  useDeletePipelineMutation,
  useGetPipelinesQuery,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IUserDelete, IUserRead, IUserBase, IUserCreate, IUserUpdate, IPipelineRead} from "../auth/types";
import { Box, Button, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "../auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PipelineFormDialog from "./PipelineFormDialog";
import AddIcon from '@mui/icons-material/Add';
import PipelineSearchBar from "./PipelineSearchBar";
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const PipeListPage = () => {

  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetPipelinesQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
      show_all: true,
    }
  );


  const columns: GridColDef[] = [
    {
      field: "id", headerName: "Id", width: 180
    },
    {
      field: "code",
      headerName: "Code",
      width: 180
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "edit", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <PipelineFormDialog pipelineId={params.row.id} />
      ),
    },
    {
      field: "delete", headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <PipelineDeleteBtn pipelineId={params.row.id} />
      ),
    },
  ];

  return (
    <>
    <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <ManageAccountsIcon />
      <Typography variant="h5" component="h1" noWrap>
        <b> Pipelines</b>
      </Typography>
    </Box>
      <Stack direction="row" gap={2} alignItems="center">
        <PipelineSearchBar onSearch={setSearchValue} size="small" />
        <PipelineFormDialog render={(props) => (
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            {...props}
          >
            Aggiungi
          </Button>
        )} />
      </Stack>
    </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "80vh", my: 2 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
}


const PipelineDeleteBtn: React.FC<{ pipelineId: IPipelineRead["id"] }> = ({ pipelineId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeletePipelineMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Pipeline", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Pipeline", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);


  return (
    <IconButton
      onClick={() => triggerDelete(pipelineId)}
      color="error"
      disabled={isLoading}
    >
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  )


}

export default withAuth(PipeListPage);
