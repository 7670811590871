import React, { } from 'react'
import { Search } from '@mui/icons-material';
import { TextField, InputAdornment, IconButton } from '@mui/material';

interface OrganizationSearchBarProps extends React.ComponentProps<typeof TextField> {
    onSearch: (searchValue: string) => void;
}

export default function UserSearchBar({ onSearch, ...rest }: OrganizationSearchBarProps) {
    const [searchValue, setSearchValue] = React.useState("");

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSearch(searchValue);
    }

    return (
        <form onSubmit={handleSearchSubmit}>
            <TextField
                placeholder="Cerca organizzazione..."
                inputProps={{ 'aria-label': 'search' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit" aria-label="search">
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                size="medium"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                {...rest}
            />
        </form>
    )
}
